import { RouteRecordRaw } from 'vue-router';
export default [
  {
    path: '/catalog',
    name: 'catalog',
    meta: {
      svgIcon: 'media/icons/duotune/abstract/abs027.svg',
      fontIcon: '',
      main: true
    },
    children: [
      {
        path: '/catalog/skill',
        name: 'skills',
        component: () => import('@/views/catalogs/skill/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      },
      {
        path: '/catalog/hardwareChecklists',
        name: 'hardwarechecklists',
        component: () =>
          import('@/views/catalogs/hardwareChecklists/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      },
      {
        path: '/catalog/fiscalEntity',
        name: 'fiscalentity',
        component: () => import('@/views/catalogs/fiscalEntity/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      },
      {
        path: '/catalog/tags',
        name: 'tags',
        component: () => import('@/views/catalogs/tags/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      },
      {
        path: '/catalog/apiKeys',
        name: 'apikeys',
        component: () => import('@/views/catalogs/apiKeys/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      },
      {
        path: '/catalog/completedReasons',
        name: 'completedreason',
        component: () => import('@/views/catalogs/completedReasons/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      },
      {
        path: '/catalog/businessUnit',
        name: 'businessunit',
        component: () => import('@/views/catalogs/businessUnit/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      },
      {
        path: '/catalog/timeZones',
        name: 'timezone',
        component: () => import('@/views/catalogs/timeZones/Index.vue'),
        meta: {
          svgIcon: '',
          fontIcon: ''
        }
      }
    ]
  }
] as RouteRecordRaw[];
